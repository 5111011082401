import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "81.59509202453987%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsSAAALEgHS3X78AAACQElEQVQ4y52TP2wSURzHX842EE1K1MXEOBgHBxeNcXHqpJuTDqZxs4ub6eTiYIwLdGgcXEw1TZo0xiZGMba2QEm1tSAQey3QcnctRaClBSwIHNzd+3p/5Diof0J/yeX98rv3Pu/7+/MIDmGUUn1tyHUI+RGI0jgURdRjpFtQ89Nsv8YjkbOjUiNYzt5WoV0Am5Dmqlk4cw/FfTtonmC7cqd7YEsdReZHFM9Dl7G13oPASi++pl8cLmXDAb6krsMXIfDMHcXQ+3MoVIrdAa21Y7NObORPA2kCp+csXPMu45b/Aa0QShUotKH7/o1BeKME38JH8HTpatt+8q/it3y5tUEt/KxwDVsqMMoTcIUJI0ylgwo7Yboqpa7HcmU/vhfeIbo3hjWBQUmwIZK9iVJt08xAO00OQmjbaGhWlxTMcLcwvtKDj1EHRK4XIe4YMuVQWwZmypqjqDdYf0pyA9zua7A7w3DHzsMfcyC+bsNqpA8LMQajwT7wex9+pyubIohVjdgwil6opuHj+/EyfAqTCwySSzawrA0zQYLHsyfxJn4JqeKUKcCaEDFAFfVNsngWuAFfYgBr6QvwxBn8TJ7AZsCBMR/B/akzancf4NXyEwSTD//6gnRgYjeC4bkrcC/aIcQIeJZg+pMd84sEk+xxTLBD2CllTYgkV80R6qw5oYpRMznlQiDAwMvZMPL5Ih55+zEavovVbXdrYtTRUCwj1Nk8Q6Eak2lVbeVbhAQnPPwgymJO7axkAcl60/40CZ3QX41vkyxDYfiEAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "viagaronna",
          "title": "viagaronna",
          "src": "/static/fef820d6c8820d391e3d003c5a40f758/a6d36/viagaronna.png",
          "srcSet": ["/static/fef820d6c8820d391e3d003c5a40f758/222b7/viagaronna.png 163w", "/static/fef820d6c8820d391e3d003c5a40f758/ff46a/viagaronna.png 325w", "/static/fef820d6c8820d391e3d003c5a40f758/a6d36/viagaronna.png 650w", "/static/fef820d6c8820d391e3d003c5a40f758/49217/viagaronna.png 701w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`Les Open Data`}</h2>
    <p>{`Les Open Data à `}<strong parentName="p">{`l'état sauvage`}</strong>{` sont des bêtes assez égoïstes, dans le sens où elles envisagent rarement les applications qui vont en être faites et qu'il est fréquent de pester parce qu'il manque telle ou telle donnée qui rendrait l'exploitation `}<strong parentName="p">{`tellement plus facile`}</strong>{`.    `}</p>
    <br />    
    <p>{`C'est normal !! Impossible de tout prévoir. Il est néanmoins pas interdit de réfléchir aux usages les plus évidents et de `}<strong parentName="p">{`doper`}</strong>{` le jeu de données pour une plus grande polyvalence et simplicité d'usage.  `}</p>
    <br />
    <center>
      <blockquote>
        <p parentName="blockquote">{`On enrichit bien l'uranium, pourquoi pas les `}<strong parentName="p">{`open data`}</strong>{` ? `}</p>
      </blockquote>
    </center>
    <br />
    <h2><u>{`Boost de niveau 1`}</u>{`: Ajouter une colonne que l'on trouve ailleurs`}</h2>
    <br />
    <p>{`Prenons par exemple `}<a parentName="p" {...{
        "href": "https://www.data.gouv.fr/fr/datasets/base-de-donnees-accidents-corporels-de-la-circulation"
      }}>{`les accidents de la route`}</a>{` en Haute-Garonne en 2018:`}</p>
    <br />
    <center>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "left"
            }}>{`Ville`}</th>
            <th parentName="tr" {...{
              "align": "right"
            }}>{`INSEE`}</th>
            <th parentName="tr" {...{
              "align": "right"
            }}>{`nb accidents`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Toulouse`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`31 555`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`461`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Colomiers`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`31 149`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`46`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Saint-Gaudens`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`31 483`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`25`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Blagnac`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`31 069`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`24`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Tournefeuille`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`31 557`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`21`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Muret`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`31 395`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`14`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Auterive`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`32 019`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`14`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Portet-sur-Garonne`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`31 433`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`10`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Balma`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`31 044`}</td>
            <td parentName="tr" {...{
              "align": "right"
            }}>{`10`}</td>
          </tr>
        </tbody>
      </table>
    </center>
    <br />
    <p>{`Sans surprise, l'agglomération Toulousaine est le siège du plus grand nombre d'accidents de la route dans le département. Mais il conviendrait de ramener les chiffres au trafic, au nombre d'usagers ou à défaut d'habitants pour espérer établir une comparaison légitime. On se dit que se serait bien pratique d'avoir une colonne supplémentaire qui renseignerait la population de la commune à la date de l'événement. Non ?   `}</p>
    <br />
    <p>{`Bien sûr cette colonne sera redondante (dupliquée à l'identique à chaque accident localisé à Toulouse), et va créer un très léger accroissement de taille du fichier (quasi annulé par les différentes compressions des dispositifs de stockage). Le data scientist professionnel va hausser les épaules: `}<em parentName="p">{`il suffit de faire la jointure sur le code INSEE avec le jeu de données des populations`}</em>{`.  `}</p>
    <br />
    <p>{`Mais les utilisateurs `}<strong parentName="p">{`normaux`}</strong>{` gagnent en confort et en simplicité à `}<strong parentName="p">{`chaque`}</strong>{` réutilisation de trouver la grandeur permettant de normaliser. `}</p>
    <br />
    <blockquote>
      <h3 parentName="blockquote">{`Le code INSEE`}</h3>
      <p parentName="blockquote">{`Ce code est l'identifiant fiable d'une commune en France. Un même code postal peut regrouper plusieurs communes et les grandes villes ont plusieurs codes postaux. Le nom de commune est parfois soumis à de multiples variantes orthographiques... Un exemple: `}<strong parentName="p">{`Saint Maur des fossés, Saint-Maur-des-fossés, St Maur des fossés, Saint Maur des Fosses`}</strong>{` dans le `}<strong parentName="p">{`Val de Marne`}</strong>{`. Oups, pardon `}<strong parentName="p">{`Val-de-Marne`}</strong>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Tous ceux qui se sont frottés à des open data nationales vous le dirons y compris en provenance d'organismes réputés, la variabilité des orthographes des noms de communes est très importante. Les codes postaux ne sont pas fiables et même si le nom de commune est stable à l'intérieur d'un même jeu de données, il n'est pas raisonnable de supposer qu'elle soit orthographiée de manière identique dans un autre jeu de données.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Le code INSEE est le seul moyen de faire un travail sérieux.`}</p>
    </blockquote>
    <br />
    <h2><u>{`Boost de niveau 2`}</u>{`: `}<strong parentName="h2">{`calculer`}</strong>{` une nouvelle colonne`}</h2>
    <p>{`Supposons les accidents ne soient repérés que par les coordonnées GPS (ce qui est le cas de nombre d'entre eux pour lesquels la commune est inconnue ou incertaine: les routes sont parfois la frontière entre communes).   `}</p>
    <br />
    <p>{`Nous n'avons plus que des points GPS. Il est devenu impossible d'aggréger les totaux pour chaque commune. Dommage ! Mais il est possible de placer les poins sur un fond de carte.`}</p>
    <p>{`Voici par exemple `}<strong parentName="p">{`les accidents de la route en 2018 de la Haute-Garonne`}</strong>{` tels qu'ils apparaissent dans l'outil `}<a parentName="p" {...{
        "href": "https://www.opendatasoft.com/fr/"
      }}>{`Open Data Soft`}</a></p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "47.239263803680984%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAACRklEQVQozy2SSVMaURSF+Wn5C/kP+QXZZJNFUqlsLFPRVGKcolYMijhFwBYVlFkUaEFomqEneqKZjIvsv7yysnj16t5T7557zjshx+6h6V20Wo6BrmBaJq1mE129Z6gU0Kol1IHJKB9ntjqH121gewamfIMj7eANNPxBX9wdXHFCzdo1eruMP3bx728wex3avT52R2F6sE7v51fsdg5/8R3WSYRJTmJy+ZtAreMJ3O2rBK7O0NHE0C4hU5UZx3/hFM8JMqfYdwUMTcfqqLgZiU7xGrWSYVSQMD++wf38Hl86JDjaQq/EGIptXdvA0xRcSyX0mIzili+ZrM8xPd5kdBbG3f6GFl5mIOphYgdrbY5BLIKT2OXh5QvMtJBfOMX7IoaXr/BqRWxFZmC1CBmlLIZl4AgJfiMvJIktq3k0webqD4ziq8ySO9iRDXrbK9Rfv8K7lngqnDPst3DvkkxuUvi++V+y7ws5aUZqDd/qMqrfYqQTeNlT0ZMZRZaYLn3gb+Q7jaM99PAKf7YWeDrbZ6K3MNRbzGaVoK/gmz1CvjDz+aHWZBYLY8aiTKJrBPlzvHad2cJbhj/mGagNWuUMj/sbPArprsCmiQi6nKUt/PPaDXpKjZArYuOIKAT5C8zSFcn7CrcVCacsajHEzsYYR5bxhJfG5idm8Q2BpQguo/iCdCbIHbmIY/dFbNqElAcZQ/xS4FhUuzKHlQId5Y5Z+oTJxTF+KcV4fV4kYZuaXKHZzfG0u4hVTzFKH+BJe3jNioiN/pzJfwbtYVBr6qJfAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Les accidents",
          "title": "Les accidents",
          "src": "/static/e578f3977aa48d799468a10b563c9d67/a6d36/accidents.png",
          "srcSet": ["/static/e578f3977aa48d799468a10b563c9d67/222b7/accidents.png 163w", "/static/e578f3977aa48d799468a10b563c9d67/ff46a/accidents.png 325w", "/static/e578f3977aa48d799468a10b563c9d67/a6d36/accidents.png 650w", "/static/e578f3977aa48d799468a10b563c9d67/e548f/accidents.png 975w", "/static/e578f3977aa48d799468a10b563c9d67/7e11a/accidents.png 1235w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Tout comme l'enrichissement de l'uranium nécessite de l'énergie et des procédés adéquats: mécanique, thermique, ... L'enrichissement des open data en capacités nouvelles réclame un effort qui ici consiste à déterminer pour un ensemble de points, à quel polygone (contours des communes) ils appartiennent.   `}</p>
    <br />
    <p>{`C'est ce qu'on appelle la `}<strong parentName="p">{`géolocalisation`}</strong>{`: on veut ajouter au jeu de données le `}<strong parentName="p">{`code INSEE déduit de la position GPS`}</strong>{`. Il existe des applications qui savent faire ce traitement, et des services web qui les réalisent pour 50 points max. Des algorithmes autonomes existent aussi.`}</p>
    <br />
    <center>
      <p>{`Voici un exemple de classeur Observablehq qui explique un algorithme rapide pour déterminer à quel polygone de commune appartiennent une liste de coordonnées GPS.    `}</p>
      <p><em parentName="p">{`Rapide comme 150k points en moins d'une seconde.`}</em></p>
      <br />
      <iframe src="/sandbox/GPS2City/index.html" width="100%" height="800" frameBorder="0" />
      <p><em parentName="p">{`Un nombre de points égal au curseur sont tirés au sort et l'algorithme détermine les polygones/communes qui les englobent. Le nom de la commune apparait en différé pour prouver qu'elle a bien été identifié. Pour plus de détails et découvrir comment ça marche: `}<a parentName="em" {...{
            "href": "https://observablehq.com/@alainro/gps-to-city-in-france"
          }}>{`https://observablehq.com/@alainro/gps-to-city-in-france`}</a>{` `}</em></p>
    </center>
    <br />
    <p>{`Tous les jeux de données constitués de localisation GPS: `}</p>
    <ul>
      <li parentName="ul">{`événements culturels, `}</li>
      <li parentName="ul">{`commerces,`}</li>
      <li parentName="ul">{`campings, `}</li>
      <li parentName="ul">{`lieux touristiques, `}</li>
      <li parentName="ul">{`hébergements, `}</li>
      <li parentName="ul">{`...   `}</li>
    </ul>
    <br />
    <p>{`Peuvent être facilement `}<strong parentName="p">{`enrichis`}</strong>{` du code INSEE de la commune. (Qui à son tour peut être exploité pour ajouter de nouveaux attributs: (`}<strong parentName="p">{`boost de niveau 1`}</strong>{`). `}</p>
    <br />
    <p>{`Chaque jeu de données est rendu là encore plus `}<strong parentName="p">{`riche`}</strong>{` et `}<strong parentName="p">{`polyvalent`}</strong>{`. `}</p>
    <h2><u>{`Boost de niveau 3`}</u>{`: calculer un `}<strong parentName="h2">{`nouveau`}</strong>{` jeu de données`}</h2>
    <br />
    <p><a parentName="p" {...{
        "href": "https://data.haute-garonne.fr/explore/dataset/via-garona/information/"
      }}>{`Via Garona`}</a>{` est un chemin de randonnée pédestre, long de 170 km, qui relie les sites de Saint-Jacques-de-Compostelle entre Toulouse (Basilique Saint-Sernin) et Saint-Bertrand-de-Comminges (Cathédrale Sainte-Marie). Le tracé est téléchargeable `}<a parentName="p" {...{
        "href": "https://data.haute-garonne.fr/explore/dataset/via-garona/"
      }}>{`ici`}</a>{` sur le site de la Haute-Garonne.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "43.558282208588956%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAACRElEQVQozzWRS09aURSF+REW5HUfPC7I08vjXuCCAqFaVEAUbai1IgpiK22MYLSkg2qa1EFn9jHroD/068Gmg519zknW2t/Zy3Y8jHE1yzCdGdx/yXE+1um8StHsxHl/mWO3k+bb1x5/fl8ynRSZXOnU60EsS6bV0Ojuh+i0IiSWJbKGF9t0mqbVjHE6rHI2ytJ9naTbK7K+FeG4v8zhgc7nmxIPd0XejQzGYuD9pxyDXpytF2FyeZll3Y0WcrIUdWH78cvicpKh3ghTW9PY2IoyGFjs7ce4GKUZnqQYnpo83JeYjAuc9JJcDHVamyGKlo+E7sWnOZ4M43EPtsfHHN9/rrKzF6VUUQWZJs4Jdjohhmcx+qc6ZwODhhjUbkVJZxXCETdRIZ5XMOzEH1wkFHaj+B3YrqcGb8cp1ucTV1We1zVau3FaOzFmH/Nc35iML0zMvEK15ieZkjBMFVPU3EjyPcMXWCQpdhhLSNhmtwYvu2FMSxEiSYhClCtB2ttx+kdZWu0IH0YW5YIIoqCiZ2Rypp9EUkIVRmrAger/3+3YdgSJVVIEnU+QxajVNEEg7oUAhqGytr7EwXaOfnOVg2aaVEolEHShiS8GNNcTpT/gRPHZcXkXsJkiJT3roVIN8OYww7YgKpb8ZFI+SpbG7bDC3fkm53tVNsqxJzNZEUQ+YaI6kRQ7XvlfuT3CUBcp5cR+SiIxUxAVin7ygm4p4iEekWisJDlplzlqriDLi7i9Qui245l3SXTJgaw6xPszXO4F/gLBdDPaNEvHKgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "woods",
          "title": "woods",
          "src": "/static/03edebf3d9e444fc19ae1717bbf91ab6/a6d36/woods.png",
          "srcSet": ["/static/03edebf3d9e444fc19ae1717bbf91ab6/222b7/woods.png 163w", "/static/03edebf3d9e444fc19ae1717bbf91ab6/ff46a/woods.png 325w", "/static/03edebf3d9e444fc19ae1717bbf91ab6/a6d36/woods.png 650w", "/static/03edebf3d9e444fc19ae1717bbf91ab6/e548f/woods.png 975w", "/static/03edebf3d9e444fc19ae1717bbf91ab6/3c492/woods.png 1300w", "/static/03edebf3d9e444fc19ae1717bbf91ab6/9c51c/woods.png 3015w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "611px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "88.95705521472392%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsSAAALEgHS3X78AAADt0lEQVQ4yzVUyXLbRhTE//+RDokrVmJbohSRhLhg3zE7MAAIMi4f2k1W5TBVqJlBv9fLm8DIEqorcJ0dJtdiVNXj+zoZrKPEaHponjvVYlIpfs0dTJ/hfHiDamKIfIvy/I4m+YHq+SsC0ZVYJ4Ubl2xL5OcdrmOHq5fctwQfMY8WRhDQaQLX6PI9ZHkgYILT/g0xwW37Dv11g2BZBky+h3EZ1qGBtwKuy1hAE1jg13XEZXIYdIfDfos8jeB1DVMfIaoITpRo6wOGwxbiOUEwOYm6DKH1CRdXYrFcQ0/KioA9ujLCZO8dK2TR52N/vAOKCpdRs3CPiYCeYJPSCO5a2TvIWEOZCIO5A5b8scNsW4jyDEfNivj4oHibNZb/5WDnnrqqL59YO94n08DKFi3FvQw1rDpB8Htyip32D/q3xeDnbNCWGQZJKTz3KMXqDW4r9a0rTK/0wdfwPA+WQcKaBjNpuiHF8fgP0ugDWfyBtAhRVzFsX6DOzjSIhVyF1dVYZ4+V+s5JgzVVpF/iQkbBhdWW+4XFo6w/UWQhepmw6xO6/sTKCk36CUuj1ovHNBqunvuarFpcvqW4aov/2O2VTQWOubu4hsJr5OxomntqIWGGirryzFObxcJTH8+90UbUUD3oLvsKt7Pg/faR2dlW7JBRSUhv8+NvKF0wIgpCn9HIPQQ11QRwjJQ2Zwh5gNU55qmCTnj2UkCzoZzulznTwEYCY2vk+QlNsaeoOTMWPwCEuYPFdJV0KIcfNCS1HG3B6aAcfyYQYYIk2yE5hRB1AdnVCLL0CCeLh8vL3T3qYgjUM0J1s3tkclkkFsblp+mw5AX8VsBmBQbm83YZOboNinSLmhMUiCbCfJ9h6rhQ7J4Bz+UWztDZ9ghNg2yYIfvjHeaNYCE1pdsFz8KPVzSM0x1QMTJx/Z2TQg1H6jIOHd1rYMaYK4LxJceRZ6GAfyJ9mWIYBKQmAItJESGl9p55XfmYDJ6gNuWkEORKkb0nBepnoy268wFjXGD+K4d7pxH82ZJ6ku5QViecklfEXEZ1pHrkpPEeg+2Y0aDLtlDhFu3LDs0Xfn+nu297qH8jjGmKstngxOfpY/uCll3KkWwYk7oOceYACEEjORx9d0QvdgjOTxuCHNA3KdLkgIgFOsamHU9YpobC94j4pDVdDE0ZChWyW8phaBznuLcJwSIOQsqoxQi2T9/w8fSMQh7xiBApVXS3KvfoOB2OD2peHLDfb1BVR0g+Hp3+pIkVteb3Pa8mheUbKlyB3/xKLBXQeYPmAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "via",
          "title": "via",
          "src": "/static/20021edcab13a82d189f19533bef2b62/36bb5/via.png",
          "srcSet": ["/static/20021edcab13a82d189f19533bef2b62/222b7/via.png 163w", "/static/20021edcab13a82d189f19533bef2b62/ff46a/via.png 325w", "/static/20021edcab13a82d189f19533bef2b62/36bb5/via.png 611w"],
          "sizes": "(max-width: 611px) 100vw, 611px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Elle traverse 41 communes, toutes situées sur le département de la Haute-Garonne, dont Toulouse, Muret, Noé, Rieux-Volvestre, Martres-Tolosane, Saint-Martory, Saint-Gaudens et Saint-Bertrand-de-Comminges. `}</p>
    <br />
    <blockquote>
      <p parentName="blockquote">{`Fort bien, et si on voulait savoir quelles communes sont limitrophes de ce parcours à moins de 2 kilomètres ? ou 3 ou 10 ? Par exemple pour y réserver un couchage, trouver une épicerie ou une pharmacie.`}</p>
    </blockquote>
    <br />
    <p>{`Au lieu de simplement ajouter une colonne à un jeu existant, on peut créer un `}<strong parentName="p">{`nouveau`}</strong>{` jeu qui rassemble les communes à moins d'une certaine `}<strong parentName="p">{`distance`}</strong>{` du parcours. L'algorithme n'a pas besoin d'être très subtil. Partant des 41 communes traversées (déterminées par le calcul précédent), il explore les communes limitrophes au rang 2, 3 ou plus et calcule la distance au chemin comme le minimum à vol d'oiseau des distances pourtour/chemin. `}<em parentName="p">{`NB: S'il est nécessaire de faire 5 kilomètres pour franchir un pont qui traverse la Garonne, l'algorithme en est évidemment ignorant.`}</em></p>
    <center>
      <p><em parentName="p">{`Voici une illustration de cette capacité de calcul à la volée des 69 communes à moins d'un kilomètre qui mériteraient d'être aussi citées comme parties prenantes de Via Garonna.`}</em>{` `}</p>
      <br />
      <iframe src="/sandbox/viagaronna/index.html" width="100%" height="800" frameBorder="0" />
      <p><em parentName="p">{`La flèche qui descend permet de télécharger au format csv la table affichée à l'écran`}</em></p>
    </center>
    <br />
    <p>{`On vient de créer un `}<strong parentName="p">{`nouveau`}</strong>{` jeu de données qui donne accès à une information qui `}<strong parentName="p">{`n'existait pas`}</strong>{` précédemment: précieuse pour les randonneurs ou les offices de tourisme des communes concernées. `}</p>
    <br />
    <p>{`Un peu comme si on avait `}<strong parentName="p">{`distillé`}</strong>{` les données existantes pour en augmenter le niveau d'intérêt et leur spectre d'usage. `}</p>
    <br />
    <p>{`Une fois la brèche ouverte il est possible de s'y engouffrer pour généraliser et créer à partir des données disponibles de nouvelles connaissances facilement exploitables. Les communes à moins d'une certaine distance:`}</p>
    <ul>
      <li parentName="ul">{`d'un cours d'eau (un chemin),`}</li>
      <li parentName="ul">{`d'une forêt (un contour), `}</li>
      <li parentName="ul">{`d'un festival (un point), `}</li>
      <li parentName="ul">{`d'un parc régional (un contour),`}</li>
      <li parentName="ul">{`d'un lycée (des points),`}</li>
      <li parentName="ul">{`d'un bureau de poste (des points),`}</li>
      <li parentName="ul">{`...`}</li>
    </ul>
    <br />
    <p><strong parentName="p">{`A retenir de ce texte:`}</strong>{`
Il est tout à fait possible d'`}<strong parentName="p">{`enrichir`}</strong>{` des données existantes `}<strong parentName="p">{`à périmètre constant`}</strong>{` grâce à des calculs adéquats. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      